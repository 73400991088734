import React from 'react';
import PropTypes from 'prop-types';
import {Tabs, Tab} from "@mui/material";

const MuiTabs = props => {
    const {tabs = [], value = 0, onChange, className} = props
    return (
        <Tabs className={className} variant={"scrollable"} scrollButtons={"auto"} value={value}
              onChange={onChange} {...props}>
            {
                tabs?.map((item, index) => (<Tab label={item} key={index}/>))
            }
        </Tabs>
    );
};

MuiTabs.propTypes = {
    tabs: PropTypes.array,
    value: PropTypes.number,
    onChange: PropTypes.func,
};

export default MuiTabs;
