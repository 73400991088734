import HomeIcon from "@mui/icons-material/Home";
import SearchIcon from '@mui/icons-material/Search';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import ShieldIcon from '@mui/icons-material/Shield';
import DashboardIcon from '@mui/icons-material/Dashboard';
import {SIEMBA_CUSTOMER} from "../../../../utils/roles";
import AllBlockersIcon from "../../../SvgIcons/AllBlockersIcon";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import GPTIconWhite from "../../../SvgIcons/GPTIconWhite";
import VAIcon from "../../../SvgIcons/VALogo";

const my_dashboard_menu = [
    {
        path: "/app/dashboard",
        icon: HomeIcon,
        label: "My Dashboard"
    },
    {
        path: "/app/enterprise_dashboard",
        icon: DashboardIcon,
        label: "Enterprise Dashboard",
        roles: [SIEMBA_CUSTOMER]
    },
    {
        path: "/app/all_assets",
        icon: WysiwygIcon,
        label: "All Assets"
    },
    {
        path: "/app/vulnerability_assessment",
        icon: VAIcon,
        label: "Vul. Assessments",
    },
    {
        path: "/app/genpt",
        icon: GPTIconWhite,
        label: "GenPT"
    },
    {
        icon: ShieldIcon,
        label: "Pentests",
        child: [
            {
                path: "/app/all_pentests",
                label: "All Pentests"
            },
            {
                path: "/app/pentests/inflight",
                label: "Inflight"
            },
            // {
            //     path: "/app/pentests/retest",
            //     label: "Retests"
            // },
            {
                path: "/app/pentests/closed",
                label: "Closed"
            },
            {
                path: "/app/pentests/upcoming",
                label: "Upcoming"
            },
            // {
            //     path: "/app/pentests/pending",
            //     label: "Pending Approval"
            // },
            {
                path: "/app/pentests/draft",
                label: "Drafts"
            },
            {
                path: "/app/all_blockers",
                // icon: AllBlockersIcon,
                label: "All Blockers"
            },
        ]
    },
    {
        path: "/app/all_findings",
        icon: SearchIcon,
        label: "All Findings"
    },
    {
        path: "/app/chat",
        icon: ChatBubbleOutlineIcon,
        label: "Chat"
    }
]
export default my_dashboard_menu;
