import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from "../Typography";
import SearchInput from "./SearchInput";
import styles from "./styles.module.scss";
import ReactTable from "../Table";
import Paper from "./Paper";
import Button from "./../../components/Button";
import moment from "moment";
import { MuiDatePicker, MuiTextField } from '../MuiComponents/FormInputs';
import Select, { components } from "react-select";
import ReactSelectMultiSelect from '../Inputs/ReactSelectMultiSelect';
import { useQueryClient } from 'react-query';
import { LIST_CUSTOMER } from '../../hooks/utils/constants';
import { setAddUsersModal, setDecommissionAssetsModal, setDeleteAssetsModal, closeDeleteAssetsModal } from '../../redux/reducers/dashboard';
import { useDispatch } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import BackspaceIcon from '@mui/icons-material/Backspace';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const AllTableData = props => {
    const {
        title,
        filterTitle,
        onSearch,
        searchText,
        tableData = [],
        columns = [],
        dependencyArray = [],
        onTableChange = () => {
        },
        hasNextPage,
        moreRef,
        description,
        tableClassName,
        hiddenColumns = [],
        filterEnabled = false,
        filterOptions,
        setFilter,
        tabIndex,
        setSearch,
        selectedCustomer,
        setSelectedCustomer,
        totalAssetCount,
        setInputValue,
        setSearchValue,
        searchEnabled = true,
        selectedRows,
        setSelectedRows,
        rowReset,
        updateCompanyId,
        expandable = false,
        exportEnabled = false,
        exportText = '',
        handleExport,
        disableExportBtn,
        nullifyDateFilter = false
    } = props

    const filterRef = useRef();
    const selectRefs = useRef([]);
    let searchRef = useRef(null);

    const [showFilter, setFilterStatus] = useState(false);
    const todayDate = new Date();
    const [startDate, setStartDate] = useState(nullifyDateFilter ? '' : new Date());
    const [endDate, setEndDate] = useState(nullifyDateFilter ? '' : new Date(todayDate.setDate(todayDate.getDate() + 1)));
    const [fromRange, setFromRange] = useState('0');
    const [toRange, setToRange] = useState('100');
    const [filterValues, setFilterValues] = useState(filterOptions);
    const [dropDownValues, setDropDownValues] = useState({});
    const queryClient = useQueryClient()
    const handleChange = (options, name) => {
        setDropDownValues({ ...dropDownValues, [name]: { ...dropDownValues[name], options } }) 
    };
    const dispatch = useDispatch()

    useEffect(() => {
        if ([0, 1].includes(tabIndex)) {
            setDropDownValues({});
            setFilterValues(filterOptions)
            setFilter(filterOptions);
            setSearch(null);
            searchRef.current.value = "";
            resetDropDownValues();
            
        }
        closeAllSelects();
    }, [tabIndex])

    //to set value of company filter field for the first time
     useEffect(() => {
        setDropDownValues({ ...dropDownValues, ['Company']: { ...dropDownValues['Company'], options: selectedCustomer } }) 
     }, [])

    // Add a ref for each react-select component
    const handleSelectRef = (ref) => {
        if (ref && !selectRefs.current.includes(ref)) {
            selectRefs.current.push(ref);
        }
    };

    useEffect(() => {
        // get the filter icon element
        const filterArrowIcon = document.getElementById('filter-arrow-icon')
        if (filterArrowIcon) {
            // get the styles of the icon
            const computedStyle = window.getComputedStyle(filterArrowIcon);
            // get the border bottom width
            const borderBottomWidth = parseFloat(computedStyle.getPropertyValue('border-bottom-width'));

            // get the filter button and filter options component
            const filterButton = document.getElementById('filter-btn-container');
            const filterOptionsContainer = document.getElementById('filter-container');

            if (filterButton && filterOptionsContainer) {
                // get the filter button and filter options position details
                const filterContainerDimensions = filterOptionsContainer.getBoundingClientRect();
                const filterButtonDimensions = filterButton.getBoundingClientRect();

                // get the styles of the filter options container
                const computedStyle = window.getComputedStyle(filterOptionsContainer);
                // get the border bottom width
                const paddingRight = parseFloat(computedStyle.getPropertyValue('padding-right'));

                // calculate the starting position from right. so this will be the filter container right position - filter button right position giving us the relative gap between the container and the button. now subtract the padding of the contianer to adjust the starting position of the arrow to the start of the button
                const startPositionFromRight = filterContainerDimensions.right - filterButtonDimensions.right - paddingRight;

                // now, add the half of the button width to the above value to set the starting position to the middle of the button. but we have to subtract the half of the width of icon to align the pointed part exactly to the center of the button
                const middlePos = startPositionFromRight + filterButtonDimensions.width/2 - borderBottomWidth/2;

                // set the position right with the calculated value
                filterArrowIcon.style.right = `${middlePos}px`;
            }
        }
    }, [showFilter])

    // Function to close all select dropdowns
    const closeAllSelects = () => {
        selectRefs.current.forEach((selectRef) => {
            if(selectRef.props.name !== "Company") {
                selectRef.onMenuClose();
            }
        });
    };
    
    const colourStyles = {
        indicatorSeparator: () => ({ display: 'none' }),
        dropdownIndicator: base => ({
            ...base,
            color: "var(--black)"
            
        }),
        menu: base => ({
            ...base,
            zIndex: 9999,
            borderRadius: 0,
            marginTop: 0,
            
            
        }),
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        menuList: base => ({
            ...base,
            padding: 0,
            
        }),
        control: (base, state) => ({
            ...base,
            background: "var(--primary-50)",
            borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
            borderColor: "var(--primary-500)",
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                borderColor: "var(--primary-500)",
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "var(--primary-500)" : "white",
            "&:hover": {
                backgroundColor: state.isSelected ? "var(--primary-300)" : "var(--background)",
            },
        }),
    }
    const ValueContainer = ({ children, ...props }) => {

        let [values, input] = children;

        if (Array.isArray(values)) {
            const val = (i) => values[i].props.children;
            const { length } = values;
            switch (length) {
                case 1:
                    values =
                        <div className="root-text">
                            {val(0)}
                        </div>

                    break;
                default:
                    const otherCount = length - 1;
                    values =
                        <div className='row'>
                            <div className="root-text">{val(0)}</div>
                            <div className="root-text">+{otherCount}</div>
                        </div>
                    break;
            }
        }
        return (
            <components.ValueContainer {...props}>
                {values}
                {input}
            </components.ValueContainer>
        );
    };

    const resetDropDownValues = (excludeFieldName='', excludeFieldValues=null) => {
        let temp = {};
        Object.keys(dropDownValues).map((ele) => {
            if(ele !== excludeFieldName) {
                temp[ele] = {
                    options: []
                }
            } else {
                temp[ele] = {
                    options: excludeFieldValues,
                }
            }
        })
        setDropDownValues(temp)
    }

    const handleFilterValues = (event, currentFilter, filterVal) => {
        const val = filterVal.toLowerCase().replace(" ", "_");
        if (currentFilter.type === "option") {
            if (!currentFilter.multiSelector) {
                if (event.target.nextElementSibling && event.target.nextElementSibling.classList.contains("active")) {
                    event.target.nextElementSibling.classList.remove("active");
                }
                if (event.target.previousElementSibling && event.target.previousElementSibling.classList.contains("active")) {
                    event.target.previousElementSibling.classList.remove("active");
                }
            }

            if (event.target.classList.contains("active")) {
                event.target.classList.remove("active");
            } else {
                event.target.classList.add("active");
            }

            const allFilters = filterValues;
            let selectedFilter, selectedFilterIndex;

            filterValues.map((value, index) => {
                if (value.name === currentFilter.name) {
                    selectedFilter = value;
                    selectedFilterIndex = index;
                }
                return true;
            });
            if (!selectedFilter.selectedValues) {
                selectedFilter["selectedValues"] = [val];
            } else {
                if (selectedFilter.multiSelector) {
                    if (selectedFilter["selectedValues"].includes(val)) {
                        const indexOfSelectedVal = selectedFilter["selectedValues"].indexOf(val);
                        selectedFilter["selectedValues"].splice(indexOfSelectedVal, 1);
                    } else {
                        selectedFilter["selectedValues"] = [...selectedFilter["selectedValues"], val];
                    }
                } else {
                    if (selectedFilter["selectedValues"].includes(val)) {
                        const indexOfSelectedVal = selectedFilter["selectedValues"].indexOf(val);
                        selectedFilter["selectedValues"].splice(indexOfSelectedVal, 1);
                    } else {
                        selectedFilter["selectedValues"] = [val];
                    }
                }
            }
            allFilters[selectedFilterIndex] = selectedFilter;            
            setFilterValues(allFilters);
            
        }
    }

    const resetFilterValues = (excludeFieldName='', onlyFieldReset=false, excludeFieldValues=null) => {
        const elements = document.querySelectorAll(".active");
        elements.forEach(function (element) {
            element.classList.remove("active");
        });
        let allFilters = filterValues;
        allFilters.map((filter) => {
            if (filter.selectedValues) {
                delete filter.selectedValues;
            }
            return true;
        });
        setFilterValues(allFilters);
        const resetDate = new Date();
        setStartDate(nullifyDateFilter ? null : new Date());
        setEndDate(nullifyDateFilter ? null : new Date(resetDate.setDate(resetDate.getDate() + 1)));
        setFromRange('0');
        setToRange('100');
        setFilter(null);
        resetDropDownValues(excludeFieldName, excludeFieldValues);
        if(!onlyFieldReset) {
            if(setSelectedCustomer){
                onSearch(null)
                setSelectedCustomer(null)
                queryClient.invalidateQueries(LIST_CUSTOMER)
                setInputValue(null)
                setSearchValue(null)
            }
        }
        
    }

    const applyFilter = () => {
        let filterOptions = {};
        filterValues.map((filter) => {

            //Asset Management Banner details prop
            if(filter.name == 'Company' && setSelectedCustomer){
                let company=null;
                if(dropDownValues["Company"]?.options?.value){
                    company = {
                        id: dropDownValues["Company"]?.options?.value,
                        label: dropDownValues["Company"]?.options?.label,
                        value: dropDownValues["Company"]?.options?.value,
                    }
                }
                setSelectedCustomer(company);
            }


            if (filter.type === "option") {
                filterOptions[filter.name] = filter.selectedValues;
            } else if (filter.type === "date") {
                filterOptions[filter.name.replace(/ /g, "_")] = {
                    "startDate": startDate,
                    "endDate": endDate,
                };
            } else if (filter.type === "range") {
                filterOptions[filter.name.replace(/ /g, "_")] = {
                    "fromRange": fromRange,
                    "toRange": toRange,
                };
            } else if (filter.type === "dropdown"){
                let temp = dropDownValues[filter.name]?.options
                let str = [];
                if(filter.singleSelect){
                    str.push(temp?.value)
                }else{
                    temp?.map((row) => {
                        str.push(row.value)
                    })
                }
                filterOptions[filter.name.replace(/ /g, "_")] = str;
            } else {
                return filterOptions;
            }
            
            return filterOptions;
        });
        setFilter(filterOptions);

        //as no company is selected, we should reset the filters
        if(!dropDownValues["Company"]?.options?.value && setSelectedCustomer){
            setFilter(null)
            return resetFilterValues()
        }


    }

    const remodelFilters = (data = []) => {
        let options = [];
        if(data && data.length) {
            data.map((json) => (
                options.push({value: json?.id,label: json?.name})
            ))
        }
        return options
    }

    const handleActionOnDecommission = () => {
        dispatch(setDecommissionAssetsModal({
            open: true,
            selectedRows: selectedRows,
            setSelectedRows: setSelectedRows,
            
        }))
      };

      const handleActionOnDelete = () => {
        dispatch(setDeleteAssetsModal({
            open: true,
            selectedRows: selectedRows,
            setSelectedRows: setSelectedRows
        }))
      };

    return (
        <Paper className={'overflow-x-hidden overflow-y-auto'}>
            <div className="grid grid-cols-12 px-5 gap-3">
                <div className="col-span-12 sm:col-span-6 lg:col-span-6">
                    <Typography variant={"h5"}>{title} <span
                        className={"text-secondary-700 capitalize"}>{filterTitle && ` - ${filterTitle === 'info' ? 'Informational' : filterTitle}`}</span></Typography>
                    <Typography variant={"subtitle1"} className={"!font-regular mt-1.5"}>{description}</Typography>
                </div>
                <div className="col-span-12 sm:col-span-6 lg:col-span-6 flex flex-wrap gap-5 justify-end">
  {
    searchEnabled &&
    <div className={`flex-grow lg:flex-grow-${filterEnabled && exportEnabled ? '6' : filterEnabled || exportEnabled ? '9' : '12'}`}>
      <SearchInput
        searchRef={searchRef}
        onChangeWithTimeout={(value) => onSearch(value)}
        placeholder={searchText}
      />
    </div>
  }
  {
    filterEnabled &&
    <div className='flex-shrink-0 lg:flex-shrink-0' id='filter-btn-container'>
      <Button
        className={"filter-btn"}
        size={"small"}
        filterIcon={true}
        onClick={() => {
          setFilterStatus(!showFilter)
        }}
      >
        filter
      </Button>
    </div>
  }
  {
    exportEnabled &&
    <div className='flex-shrink-0 lg:flex-shrink-0'>
      <Button
        className={`!bg-secondary-700 !px-[14px]`}
        disabled={disableExportBtn}
        size={"small"}
        exportIcon={true}
        onClick={() => {
          handleExport()
        }}
      >
        {exportText}
      </Button>
    </div>
  }
</div>

            </div>
            <div className={showFilter ? "grid grid-cols-12 px-5 gap-3" : "hide-filter"}>
                <div className="col-span-12 filter-component" id="filter-container">
                    <div className="filter-options">
                        <div className="box-indicator" id="filter-arrow-icon"></div>
                        {
                            filterEnabled && filterOptions.map((filter) => (
                                <div
                                    className={`${filter.fullWidth ? "filter w-100" : `filter ${filter.firstFilter ? "first-filter" : filter.lastFilter ? "last-filter" : ""}`}`}
                                >
                                    <div className="name fz-16-lh-22 mb-2">
                                        {filter.name}
                                    </div>
                                    {
                                        filter.type === "option" ?
                                            <div className="option">
                                                <ul>
                                                    {
                                                        filter.filterValues.map((value, index) => (
                                                            <li
                                                                className={
                                                                    filter.defaultSelected && filter.defaultSelected.includes(value.toLowerCase()) ? 'active' : ''
                                                                }
                                                                key={index}
                                                                ref={filterRef}
                                                                onClick={(event) => {
                                                                    handleFilterValues(event, filter, value);
                                                                }}
                                                            >{value === 'Info' ? 'Information Gathering' : value}</li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                            :
                                            filter.type === "date" ?
                                                <div className="date">
                                                    <div className="from-date">
                                                        <MuiDatePicker
                                                            onChange={(date) => {
                                                                const dateVal = new Date(date);
                                                                if (date !== 'Invalid Date' && date !== null) {
                                                                    setStartDate(dateVal)
                                                                    if (moment(dateVal).isAfter(endDate, 'day')) {
                                                                        const endDate = new Date(date);
                                                                        setEndDate(new Date(endDate.setDate(endDate.getDate() + 1)));
                                                                    }
                                                                } else {
                                                                    setStartDate(startDate)
                                                                    if (moment(dateVal).isAfter(endDate, 'day')) {
                                                                        setEndDate(new Date(endDate.setDate(endDate.getDate() + 1)));
                                                                    }
                                                                }
                                                            }}
                                                            value={startDate}
                                                        />
                                                    </div>
                                                    <div>to</div>
                                                    <div className="to-date">
                                                        <MuiDatePicker
                                                            onChange={(date) => {
                                                                const dateVal = new Date(date);
                                                                if (date !== 'Invalid Date' && date !== null) {
                                                                    setEndDate(date);
                                                                    if (moment(dateVal).isBefore(startDate, 'day')) {
                                                                        const startDateVal = new Date(date);
                                                                        setStartDate(new Date(startDateVal.setDate(startDateVal.getDate() - 1)));
                                                                    }
                                                                } else {
                                                                    setEndDate(dateVal);
                                                                    if (moment(dateVal).isBefore(startDate, 'day')) {
                                                                        const startDate = dateVal;
                                                                        setStartDate(new Date(startDate.setDate(startDate.getDate() - 1)));
                                                                    }
                                                                }
                                                            }}
                                                            value={endDate}
                                                        />
                                                        {/* <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} /> */}
                                                    </div>
                                                </div>
                                                :
                                                filter.type === "dropdown" ?
                                                    <div className="dropdown">
                                                        <div className="init-dropdown">                                                                  
                                                            <ReactSelectMultiSelect
                                                                name={filter.name}
                                                                eleRef={handleSelectRef}
                                                                className="indiv-dropdown text-capitalize"
                                                                options={remodelFilters(filter.filterValues)}
                                                                isClearableCheck={filter.isClearable ? true : null}
                                                                isMulti={filter.singleSelect ? null : true}
                                                                hideSelectedOptions={false}
                                                                ValueContainer={ ValueContainer }
                                                                styles={colourStyles}
                                                                placeholder={filter.placeholder ? filter.placeholder : ""}
                                                                onChange={(options) => {
                                                                    if(filter.name === "Company") {
                                                                        if(!options) {
                                                                            closeAllSelects();
                                                                        }
                                                                        if(updateCompanyId) {
                                                                            updateCompanyId(options?.value);
                                                                        }
                                                                        resetFilterValues("Company", true, options);
                                                                    } else {
                                                                        handleChange(options, filter.name)
                                                                    }
                                                                }}
                                                                value={dropDownValues[filter.name]?.options}
                                                            />
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="range">
                                                        <div className="from-range">
                                                            <MuiTextField
                                                                value={fromRange}
                                                                onChange={(event) => {
                                                                    const val = event.target.value;
                                                                    if (val >= 0) {
                                                                        setFromRange(val);
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                        <div>to</div>
                                                        <div className="to-range">
                                                            <MuiTextField
                                                                value={toRange}
                                                                onChange={(event) => {
                                                                    const val = event.target.value;
                                                                    if (val >= 0) {
                                                                        setToRange(val);
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                    }
                                </div>
                            ))
                        }
                    </div>
                    <div className="filter-actions">
                        <Button
                            size={"small"}
                            variant={"outlined"}
                            className={"!text-primary-800 w-full sm:w-auto"}
                            type="reset"
                            onClick={() => resetFilterValues()}
                        >
                            clear
                        </Button>
                        <Button
                            onClick={() => applyFilter()}
                            size={"medium"}
                            variant={"contained"}
                            className="ml-3"
                        >
                            apply filters
                        </Button>
                    </div>
                </div>
            </div>
            {
                selectedRows && selectedRows.length > 0 &&
                <div className={'bg-background p-2 mx-5 my-4 rounded'}>
                <div className={''}>
                <Button
                            size={"small"}
                            variant={"text"}
                            style={{ backgroundColor: 'transparent' }}
                            className={"!text-functional-red !bg-none mr-4"}
                            type="reset"
                            onClick={handleActionOnDelete}
                            // disabled={selectedRows.some(jsonObj => jsonObj.stage === 'decommissioned')}
                        >
                            <DeleteIcon className={'mx-2'}/>Delete Assets <NavigateNextIcon className={'mx-1'}/>
                        </Button>
                        <span className={'border-l border-gray-300 h-10'}></span>
                        <Button
                            size={"small"}
                            variant={"text"}
                            style={{ backgroundColor: 'none' }}
                            className={`${selectedRows.some(jsonObj => jsonObj.stage === 'decommissioned') ? `!text-grey1` : `!text-tertiary-900`} !bg-none mr-4`}
                            type="reset"
                            onClick={handleActionOnDecommission}
                            disabled={selectedRows.some(jsonObj => jsonObj.stage === 'decommissioned')}
                        >
                            <BackspaceIcon className={'mx-2'}/>Decommission Assets <NavigateNextIcon className={'mx-1'}/>
                        </Button>
                </div>
            </div>
            }
            <div className={`${styles.data} ${tableClassName}`}>
                <ReactTable
                    data={tableData}
                    columns={columns}
                    className={"table lg:table-fixed"}
                    headerClassName={"background rounded"}
                    bodyClassName={"text-headings"}
                    dependencyArray={dependencyArray}
                    manualSortBy={true}
                    expandable={expandable}
                    onTableChange={onTableChange}
                    hiddenColumns={hiddenColumns}
                    emptyDataText={title == 'All Assets' ? (totalAssetCount == 0 ? "-No assets available. Start by creating an asset-" : null) : null}
                />
                {hasNextPage && <div className={"h-[10px]"} ref={moreRef}></div>}
            </div>
        </Paper>
    );
};

AllTableData.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    filterTitle: PropTypes.string,
    onSearch: PropTypes.func,
    searchText: PropTypes.string,
    tableData: PropTypes.array,
    columns: PropTypes.array,
    dependencyArray: PropTypes.array,
    onTableChange: PropTypes.func,
    hasNextPage: PropTypes.bool,
    moreRef: PropTypes.any,
    tableClassName: PropTypes.string,
    hiddenColumns: PropTypes.array,
    filterEnabled: PropTypes.bool,
    filterOptions: PropTypes.array,
    setFilter: PropTypes.func,
    searchEnabled: PropTypes.bool,
};

export default AllTableData;
