import React, {Suspense} from 'react';
import {Drawer} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {useDispatch, useSelector} from "react-redux";
import EXPLORE_CONSTANTS from "./utils/EXPLORE_CONSTANTS";
import {closeExplore} from "../../redux/reducers/dashboard";
import MainLoader from "../../components/Loader/MainLoader";
import VulnerabilityAssessment from './VulnerabilityAssessment';

const Finding = React.lazy(() => import('./Finding'))
const Pentest = React.lazy(() => import('./Pentest'))
const Asset = React.lazy(() => import('./Asset'))
const UserDetails = React.lazy(() => import('./UserDetails'))

const Explore = () => {
    const {
        explore: {
            id,
            info,
            dashboard,
            role=null,
            currentActiveTab=null,
        }
    } = useSelector(state => state.dashboard)
    const dispatch = useDispatch()
    const close = () => {
        dispatch(closeExplore())
    }
    return (
        <div>
            <Drawer
                open={!!dashboard}
                anchor={"right"}
                PaperProps={{
                    style: {
                        width: 650,
                        borderRadius: '10px 0 0 10px'
                    }
                }}
            >
                <div className={"relative"}>
                    <div className="absolute top-3 right-3"><CloseIcon onClick={close}
                                                                       className={"text-body cursor-pointer"}/></div>
                    <div className={"my-9 mx-5"}>
                        <Suspense fallback={<MainLoader/>}>
                            {dashboard === EXPLORE_CONSTANTS.FINDING && <Finding id={id}/>}
                            {dashboard === EXPLORE_CONSTANTS.PENTEST && <Pentest id={id}/>}
                            {dashboard === EXPLORE_CONSTANTS.ASSET && <Asset id={id}/>}
                            {dashboard === EXPLORE_CONSTANTS.VULNERABILITY_ASSESSMENT && <VulnerabilityAssessment id={id} info={info}/>}
                            {dashboard === EXPLORE_CONSTANTS.USER_DETAILS && <UserDetails id={id} role={role} activeTab={currentActiveTab} />}
                        </Suspense>
                    </div>
                </div>
            </Drawer>

        </div>
    );
};

export default Explore;
