export const findingStatus = {
    potential: {
        title: "Potential",
        color: "var(--functional-blue-600)",
        backgroundColor: "rgba(18, 175, 184, 0.2)"
    },
    open: {
        title: "Open",
        color: "var(--functional-red-900)",
        backgroundColor: "rgba(154, 35, 53, 0.2)"
    },
    passed: {
        title: "Passed",
        color: "var(--functional-blue-600)",
    },
    failed: {
        title: "Failed",
        color: "var(--functional-blue-600)",
    },
    // info: {
    //     title: "Info",
    //     color: "var(--functional-blue-600)",
    // },
    retest_ready: {
        title: "Retest Ready",
        color: "var(--functional-blue)",
        backgroundColor: "rgba(34, 142, 188, 0.2) "
    },
    retest_failed: {
        title: "Retest Failed",
        color: "var(--functional-red-700)",
        backgroundColor: "rgba(204, 57, 79, 0.2)"
    },
    rejected: {
        title: "Rejected",
        color: "var(--functional-blue-50)",
        alternateColor: "rgba(0, 0, 0, 0.6)",
        backgroundColor: "rgba(207, 226, 243, 0.3)"
    },
    remediated: {
        title: "Remediated",
        color: "var(--success-green)",
        backgroundColor: "rgba(58, 218, 122, 0.2)"
    },
    risk_accepted: {
        title: "Risk Accepted",
        color: "var(--functional-orange)",
        backgroundColor: "rgba(254, 119, 84, 0.2)"
    },
    risk_transferred: {
        title: "Risk Transferred",
        color: "var(--functional-yellow)",
        backgroundColor: "rgba(255, 231, 101, 0.2)"
    },
    passed: {
        title: "Passed",
        color: "var(--success-green)",
    },
    failed: {
        title: "Failed",
        color: "var(--functional-red-900)",
    },
    // info: {
    //     title: "Info",
    //     color: "var(--functional-purple)",
    // }
}

export const qualysFindingStatus = {
    new: {
        title: "New",
        color: "#12AFB8",
        backgroundColor: "rgba(18, 175, 184, 0.2)"
    },
    active: {
        title: "Active",
        color: "#9A2335",
        backgroundColor: "rgba(154, 35, 53, 0.2)"
    },
    fixed: {
        title: "Fixed",
        color: "#3ADA7A",
        backgroundColor: "rgba(58, 218, 122, 0.2)"
    },
    're-opened': {
        title: "Re-Opened",
        color: "#FE7754",
        backgroundColor: "rgba(254, 119, 84, 0.2)"
    },
    'retest-ready': {
        title: "Retest Ready",
        color: "var(--functional-blue)",
        backgroundColor: "rgba(34, 142, 188, 0.2) "
    },
}

export const genptFindingStatus = {
    new: {
        title: "New",
        color: "var(--functional-blue-600)",
    },
    existing: {
        title: "Existing",
        color: "var(--functional-red-900)",
    },
    reopened: {
        title: "Re-opened",
        color: "var(--functional-blue-600)",
    },
    fixed: {
        title: "Fixed",
        color: "var(--functional-blue-600)",
    },
}

// export const likelihoodStatus = {
//     low: {
//         title: "Low",
//         status: "low"
//     },
//     medium: {
//         title: "Medium",
//         status: "medium"
//     },
//     high: {
//         title: "High",
//         status: "high"
//     },
//     critical: {
//         title: "Critical",
//         status: "critical"
//     },
//     info: {
//         title: "Informational",
//         status: "info"
//     }
// }

export const likelihoodStatus = {
    veryLikely: {
        title: "Very Likely",
        status: "veryLikely"
    },
    likely: {
        title: "Likely",
        status: "likely"
    },
    possible: {
        title: "Possible",
        status: "possible"
    },
    unlikely: {
        title: "Unlikely",
        status: "unlikely"
    },
    veryUnlikely: {
        title: "Very Unlikely",
        status: "veryUnlikely"
    }
}

export const severityStatus = {
    low: {
        title: "Low",
        status: "low"
    },
    medium: {
        title: "Medium",
        status: "medium"
    },
    high: {
        title: "High",
        status: "high"
    },
    critical: {
        title: "Critical",
        status: "critical"
    },
    info: {
        title: "Info",
        status: "info"
    }
}

export const findingColors = {
    critical: {
        name: "Critical",
        color: "var(--functional-red-900)",
        value: 100
    },
    high: {
        name: "High",
        color: "var(--functional-red-700)",
        value: 75
    },
    medium: {
        name: "Medium",
        color: "var(--functional-orange)",
        value: 50
    },
    low: {
        name: "Low",
        color: "var(--functional-yellow)",
        value: 25
    },
    info: {
        name: "Info",
        color: "var(--functional-blue-50)",
        value: 12.5
    }
}

export const assetRiskScoreColors = {
    critical: {
        name: "Critical",
        color: "#9A2335",
        value: 100
    },
    high: {
        name: "High",
        color: "#CC394F",
        value: 75
    },
    moderate: {
        name: "Moderate",
        color: "#FE7754",
        value: 50
    },
    low: {
        name: "Low",
        color: "#FFE765",
        value: 25
    },
    minimal: {
        name: "Minimal",
        color: "#CFE2F3",
        value: 12.5
    }
}

export const alertFindingsStatus = [
    'open',
    'retest_ready',
    'retest_failed'
]

export const closedFindingsStatus = [
    'remediated',
    'rejected',
    'risk_accepted',
    'risk_transferred'
]
