export const VALIDATE_LOGIN = 'VALIDATE_LOGIN'
export const CUSTOMER_DETAILS = 'CUSTOMER_DETAILS'
export const EXISTING_ASSETS = 'EXISTING_ASSETS'
export const NEW_ASSETS = 'NEW_ASSETS'
export const LIST_ALL_PENTEST_ASSETS = 'LIST_ALL_PENTEST_ASSETS'
export const LIST_ALL_COMPANY_ASSETS = 'LIST_ALL_COMPANY_ASSETS'
export const LIST_ALL_PENTEST_ASSETS_REVIEW = 'LIST_ALL_PENTEST_ASSETS_REVIEW'
export const LIST_CUSTOMER_TEAM = 'LIST_CUSTOMER_TEAM'
export const LIST_ALL_CUSTOMER_TEAM = 'LIST_ALL_CUSTOMER_TEAM'
export const LIST_CUSTOMER_TEAM_BY_ROLE = 'LIST_CUSTOMER_TEAM_BY_ROLE'
export const LIST_CUSTOMER_TEAM_INVITES = 'LIST_CUSTOMER_TEAM_INVITES'
export const NEXT_PENTEST_NAME = 'NEXT_PENTEST_NAME'
export const LIST_ASSIGNED_CUSTOMER = 'LIST_ASSIGNED_CUSTOMER'
export const PENTEST_COUNT_BY_STAGE = 'PENTEST_COUNT_BY_STAGE'
export const OPEN_FINDING_COUNT = 'OPEN_FINDING_COUNT'
export const WELCOME_BANNER = 'WELCOME_BANNER'
export const BANNER_DETAILS = 'BANNER_DETAILS'
export const BREAKDOWN_DETAILS = 'BREAKDOWN_DETAILS'
export const YOUR_ASSETS = 'YOUR_ASSETS'
export const USER_MANAGEMENT = 'USER_MANAGEMENT'
export const ASSET_MANAGEMENT = 'ASSET_MANAGEMENT'
export const YOUR_PENTESTS = 'YOUR_PENTESTS'
export const ALL_FINDINGS = 'ALL_FINDINGS'
export const ALL_BLOCKERS = 'ALL_BLOCKERS'
export const BLOCKER_CHATS = 'BLOCKER_CHATS'
export const DRAFT_FINDINGS = 'DRAFT_FINDINGS'
export const FINDINGS = 'FINDINGS'
export const FINDINGS_STATS = 'FINDINGS_STATS'
export const FINDINGS_HISTORY = 'FINDINGS_HISTORY'
export const PENTEST_STATUS = 'PENTEST_STATUS'
export const LIST_TEAM = 'LIST_TEAM'
export const LIST_SIEMBA_ASSETS = 'LIST_SIEMBA_ASSETS'
export const LIST_CUSTOMER = 'LIST_CUSTOMER'
export const POINTS_CORNER = 'POINTS_CORNER'
export const PENTEST_SUCCESS_TEAM = 'PENTEST_SUCCESS_TEAM'
export const OVERALL_LANDSCAPE_AND_PENTEST = 'OVERALL_LANDSCAPE_AND_PENTEST'
export const PENTESTS = 'PENTESTS'
export const ASSETS = 'ASSETS'
export const ASSET_USER_STORIES = 'ASSET_USER_STORIES'
export const LOGOUT = 'LOGOUT'
export const ENTERPRISE_ASSETS = 'ENTERPRISE_ASSETS'
export const KEY_INSIGHTS = 'KEY_INSIGHTS'
export const QUALYS_KEY_INSIGHTS = 'QUALYS_KEY_INSIGHTS'
export const PRE_REQUISITES = 'PRE_REQUISITES'
export const PRE_REQUISITES_COMMENTS = 'PRE_REQUISITES_COMMENTS'
export const FINDING_BREAKDOWN_CHART = 'FINDING_BREAKDOWN_CHART'
export const FINDING_BREAKDOWN_STATUS_CHART = 'FINDING_BREAKDOWN_STATUS_CHART'
export const REPORTS = 'REPORTS'
export const QUALYS_REPORTS = 'QUALYS_REPORTS'
export const GENPT_REPORTS = 'GENPT_REPORTS'
export const CHAT_TEAM = 'CHAT_TEAM'
export const CONVERSATIONS = 'CONVERSATIONS'
export const INBOX = 'INBOX'
export const COMPANY = 'COMPANY'
export const NOTIFICATIONS = 'NOTIFICATIONS'
export const PENTEST_ASSETS = 'PENTEST_ASSETS'
export const USER_DETAILS = 'USER_DETAILS'
export const IMPORT_ASSETS = 'IMPORT_ASSETS'
export const ASSET_DEPARTMENTS = 'ASSET_DEPARTMENTS'
export const PENTEST_ITERATION_HISTORY_DETAILS = 'PENTEST_ITERATION_HISTORY_DETAILS'
export const SIEMBAGPT_SESSIONS = 'SIEMBAGPT_SESSIONS'
export const SIEMBAGPT_SESSIONS_FINDINGS = 'SIEMBAGPT_SESSIONS_FINDINGS'
export const SIEMBAGPT_SESSION_DETAILS = 'SIEMBAGPT_SESSION_DETAILS'
export const SIEMBAGPT_SESSION_INSIGHTS = 'SIEMBAGPT_SESSION_INSIGHTS'
export const SIEMBAGPT_USER_SESSION_INSIGHTS = 'SIEMBAGPT_USER_SESSION_INSIGHTS'
export const GENPT_TEST_KEY_INSIGHT = 'GENPT_TEST_KEY_INSIGHT'
export const IMPORT_GENPT_TESTS = 'IMPORT_GENPT_TESTS'
export const VULNERABILITY_ASSESSMENT = 'VULNERABILITY_ASSESSMENT'
export const VULNERABILITY_DATA = 'VULNERABILITY_DATA'
export const VULNERABILITY_LIST_DATA = 'VULNERABILITY_LIST_DATA'
export const VULNERABILITY_INSIGHTS = 'VULNERABILITY_INSIGHTS'
export const LIST_VULNERABILITY_ASSESSMENT = 'LIST_VULNERABILITY_ASSESSMENT'
export const LIST_SCAN_HISTORY = 'LIST_SCAN_HISTORY'

