import React from 'react';
import {
    AllAssets,
    AllAssetsFilter, AllBlockers,
    AllFindings,
    AllFindingsFilter, AllHighRiskAssets, AllPentests, AllPentestsFilter, AssetDashboard, AssetDescAndUserStories, Chat,
    Dashboard, EditAssetScope, EnterpriseDashboard,
    FindingDashboard, MyCustomers, PentestDashboard, PentestLandscape, PreRequisites, VADashboard, QualysFindingDashboard
} from "../pages/PageListAsync";
import { Route, Routes, Navigate } from "react-router-dom";
import Layout from "../../components/Admin/Layout";
import LayoutProvider from "../../Providers/LayoutProvider";
import withPermissions from "../../hoc/withPermissions";
import { SIEMBA_TESTER } from "../../utils/roles";
import DraftFindings from '../pages/MyDashboard/FindingDashboard/DraftFindings';
import VulnerabilityAssessment from '../pages/Admin/VulnerabilityAssessment';

const TesterRoutes = () => {
    return (
        <LayoutProvider value={{
            dashboard: "TESTER_DASHBOARD",
            mainUrl: "/tester"
        }}>
            <Layout>
                <Routes>
                    <Route path={"/"} element={<Navigate to={"/tester/dashboard"} />} />
                    <Route path={"/dashboard"} element={<Dashboard dashboard={"TESTER_DASHBOARD"} />} />
                    <Route path={"dashboard/:company_id/enterprise_dashboard"} element={<EnterpriseDashboard />} />
                    <Route path={"customers"} element={<MyCustomers />} />
                    <Route path={"vulnerability_assessment"} element={<VulnerabilityAssessment />} />
                    {/*findings*/}
                    <Route path={"/dashboard/draft_findings"} element={<DraftFindings />} />
                    <Route path={"/all_findings"} element={<AllFindings />} />
                    <Route path={"/dashboard/:company_id/all_findings"} element={<AllFindings />} />
                    <Route path={"/all_findings/:id/dashboard"} element={<FindingDashboard />} />
                    <Route path={"/all_findings/:filter"} element={<AllFindingsFilter />} />
                    <Route path={"/dashboard/:company_id/all_findings/:filter"} element={<AllFindingsFilter />} />
                    <Route path={"/va_findings/:findingId/dashboard"} element={<QualysFindingDashboard />} />
                    {/*assets*/}
                    <Route path={"/all_assets"} element={<AllAssets />} />
                    <Route path={"/all_assets/riskiest_assets"} element={<AllHighRiskAssets />} />
                    <Route path={"/all_assets/riskiest_assets/:pentest_id"} element={<AllHighRiskAssets />} />
                    <Route path={"/all_assets/:id/dashboard"} element={<AssetDashboard />} />
                    <Route path={"/va/:assetId/dashboard"} element={<VADashboard />} />
                    <Route path={"/all_assets/:filter"} element={<AllAssetsFilter />} />
                    <Route path={"/all_assets/:id/edit_scope"} element={<EditAssetScope />} />
                    <Route path={"/all_assets/:id/asset_desc_and_user_stories"} element={<AssetDescAndUserStories />} />
                    <Route path={"/all_assets/:asset_id/all_findings"} element={<AllFindings />} />
                    <Route path={"/all_assets/:asset_id/all_findings/:filter"} element={<AllFindingsFilter />} />
                    <Route path={"/all_assets/:asset_id/pre_requisites"} element={<PreRequisites />} />
                    {/*pentests*/}
                    <Route path={"/all_pentests"} element={<AllPentests />} />
                    <Route path={"/pentests/:filter"} element={<AllPentestsFilter />} />
                    <Route path={"/all_pentests/:id/dashboard"} element={<PentestDashboard />} />
                    <Route path={"/all_pentests/:pentest_id/all_findings"} element={<AllFindings />} />
                    <Route path={"/all_pentests/:pentest_id/pentest_landscape"} element={<PentestLandscape />} />
                    <Route path={"/all_pentests/:pentest_id/all_findings/:filter"} element={<AllFindingsFilter />} />
                    {/*blockers*/}
                    <Route path={"/all_blockers"} element={<AllBlockers />} />
                    <Route path={"/dashboard/:company_id/all_blockers"} element={<AllBlockers />} />
                    <Route path={"/chat"} element={<Chat />} />
                    <Route path={"/chat/:conversation_id"} element={<Chat />} />
                </Routes>
            </Layout>
        </LayoutProvider>
    );
};

export default withPermissions(TesterRoutes, { roles: [SIEMBA_TESTER] });
