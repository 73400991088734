import {useQuery} from "react-query";
import {getImage, getTemplate} from "../api/queries/misc";
import { FINDINGS_STATS } from "./utils/constants";
import axios from "axios";
import { paginationAttr } from "../actions/commonActions";

export const useImage = (key, type = null, url = true ) => {
    let res = useQuery(['image', key, type], getImage, {
        staleTime: 800000
    })
    if (url)
        return res?.data
    return res;
}

export const useTemplate = (key, type, url = true) => {
    let res = useQuery([key, type], getTemplate, {
        staleTime: 900000
    });

    if (url) {
        return res?.data;
    }

    return res;
};


export const useMasterLists = (options = {}) => {
    const {
        type = null,
        searchKey = null
    } = options
    return useQuery([FINDINGS_STATS, type, searchKey], async () => {
        let res = await axios.get(`misc/master/master_lists/${paginationAttr({
            type, searchKey
        })}`)
        return res.data;
    }, {
        ...options
    })
}