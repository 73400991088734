export const API_URL = process.env.REACT_APP_API_BASE_URL
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID
export const CHAT_WEB_SOCKET_URL = process.env.REACT_APP_CHAT_WEB_SOCKET_URL
export const SLACK_CLIENT_ID = process.env.REACT_APP_SLACK_CLIENT_ID
export const PRODUCT_FRUITS_WORKSPACE_CODE = process.env.REACT_APP_PRODUCT_FRUITS_WORKSPACE_CODE
export const IMPORT_ASSETS_TEMPLATE_KEY = process.env.REACT_APP_IMPORT_ASSETS_TEMPLATE_KEY
export const SIEMBAGPT_ENABLED = process.env.REACT_APP_SIEMBAGPT_ENABLED
export const IMPORT_GENPT_URLS_TEMPLATE_KEY = process.env.REACT_APP_IMPORT_GENPT_URLS_TEMPLATE_KEY
export const ENABLE_QUALYS = process.env.REACT_APP_ENABLE_QUALYS

/**
 * redirect to specefic routes based on customer profile status
 * @type {{pentest_review: string, pentest_profile: string, profile: string, pentest_team: string, pentest_scope: string, dashboard: string}}
 */
export const SIEMBA_CUSTOMER_STAGES_ROUTES = {
  "profile": "/profile",
  "pentest_profile": "/pentest/profile",
  "pentest_scope": "/pentest/scope",
  "pentest_team": "/pentest/team",
  "pentest_team_overseers": "/pentest/team_overseers",
  "pentest_team_asset_poc": "/pentest/team_asset_poc",
  "pentest_review": "/pentest/review",
  "dashboard": "/app",
}

export const DATE_FORMAT = "MM/DD/YY"

export const userTypes = {
  PROVIDER: 'provider',
  CUSTOMER: 'customer',
};

export const BLOCKER_STATUS = {
  OPEN: "open",
  CLOSED: "closed",
  RESOLVED: "resolved",
  REOPENED: "reopened"
}

export const capitalizeFirstLetter = (str) => {
  return str?.charAt(0)?.toUpperCase() + str?.slice(1);
};

export const AssetCriticality = {
  "P1": {
    title: 'Mission Critical',
    description: [
      "Disruption or loss of these assets could cause severe consequences: financial losses, safety hazards, environmental damage, and reputational harm.",
      "Likely holds highly sensitive data (high impact on Confidentiality).",
      "Supports essential business functions (high impact on Integrity).",
      "Vital for overall organizational security and stability (high impact on Availability).",
    ],
    color: 'var(--functional-red-900)'
  },
  "P2": {
    title: 'Business Critical',
    description: [
      "Important for organizational success and stability; not as critical as mission-critical assets.",
      "Their disruption would significantly hinder operations, causing financial losses or reputational damage.",
      "Likely holds moderately sensitive information (moderate to high impact on Confidentiality).",
      "Supports vital business processes (moderate to high impact on Integrity).",
      "Essential for daily operations (moderate to high impact on Availability)."
    ],
    color: 'var(--functional-red-700)'
  },
  "P3": {
    title: 'Significant Impact',
    description: [
      "Important for efficiency; not directly linked to core mission or financial stability.",
      "Disruption could cause inconvenience and delays, impacting productivity, but could be mitigated without major consequences.",
      "Likely holds non-critical information (moderate impact on Confidentiality), supports specific business functions (moderate impact on Integrity).",
      "Essential for smooth workflow (moderate impact on Availability)."
    ],
    color: 'var(--functional-orange)'
  },
  "P4": {
    title: 'Limited Impact',
    description: [
      "Minimal impact on overall operations; easily replaceable or mitigated.",
      "Disruption causes inconvenience without major impacts on productivity or finances.",
      "Likely holds publicly available information (low impact on Confidentiality).",
      "Has limited impact on business processes (low to moderate impact on Integrity).",
      "Noticeable absence but not detrimental (low to moderate impact on Availability)."
    ],
    color: 'var(--functional-yellow)'
  },
  "P5": {
    title: 'Negligible Impact',
    description: [
      "Virtually no impact on the organization; could go unnoticed if lost.",
      "Loss would have no impact on operations or finances.",
      "Contains non-sensitive or publicly available information (low impact on Confidentiality).",
      "No effect on business processes (low impact on Integrity).",
      "Absence would be undetectable (low impact on Availability)."
    ],
    color: 'var(--functional-blue-50)'
  }
};
