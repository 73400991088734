export const required_message = "cannot be empty";
export const otp = {pattern: {value: /^[0-9]{1,6}$/, message: "Otp is not valid"}}
export const nameValidation = {pattern: {value: /^[a-zA-Z ]{2,30}$/, message: "Invalid name"}}
export const designation = {pattern: {value: /^\s*[a-zA-Z.\s]+\s*$/, message: "Invalid designation"}}
export const authentication_code = {pattern: {value: /^[0-9]{1,6}$/, message: "Authentication code is not valid"}}
export const email = {pattern: {value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: "Invalid email id"}}
export const address = {pattern: {value: /^[#.0-9a-zA-Z\s,-]+$/, message: "Invalid address"}}
export const number = {pattern: {value: /^\d+$/, message: "Invalid number"}}
export const decimalNumber = {pattern: {value: /^\d*(\.\d+)?$/, message: "Invalid number"}}
export const version_number = {pattern: {value: /^(\d+\.)?(\d+\.)?(\*|\d+)$/, message: "Invalid version number"}}
export const dataSpaceValidation = {pattern: {
    value: /^[^\s]+(?:$|.*[^\s]+$)/,
    message: "Entered value cant start/end or contain only white spacing"
}}
export const mandatory_field = "This is a mandatory field";

export const validateTitleInput = (value) => {
    if (value.trim() === "") {      
      return "Blank input is not allowed";
    }
    return true;
  };

  export const specialCharacterCheck= {pattern: {
    value: /^(?!\s*$)[A-Za-z0-9,;'".\s\n]+$/,
    message: "Entered text contains extra special characters or spaces"
}}

export const allCharactersWithSpaceValidation= {pattern: {
    value:  /^(?!\s)(?!.*\s$)(?!\s*$).*$/,
    message: "Entered text contains extra spaces"
}}

export const domain = {
    pattern: {
        //eslint-disable-next-line
        value: /^(((?!\-))(xn\-\-)?[a-z0-9\-_]{0,61}[a-z0-9]{1,1}\.)*(xn\-\-)?([a-z0-9\-]{1,61}|[a-z0-9\-]{1,30})\.[a-z]{2,}$/,
        message: "Invalid domain"
    }
}

export const urlWithoutProtocol = {
    pattern: {
        //eslint-disable-next-line
        value: /^(?!(https?:\/\/|www\.))(?![\s\S]*:\/\/|\S*\.\S*\s).+$/,
        message: "The domain name you provided is not valid. Please enter the url without https://, http:// or www."
    }
}

export const password = {
    validate: {
        isLong: (value) => /(?=.{8,})/.test(value) || "The string must be eight characters or longer",
        isLower: (value) => /(?=.*[a-z])/.test(value) || "The password must contain at least 1 lowercase",
        isUpper: (value) => /(?=.*[A-Z])/.test(value) || "The password must contain at least 1 uppercase",
        isNumeric: (value) => /(?=.*[0-9])/.test(value) || "The password must contain at least 1 number",
        isSpecialChar: (value) => /(?=.*[!@#$%^&*])/.test(value) || "The password must contain at least 1 special character",
        isWhiteSpace: (value) => !/\s/.test(value) || "The password should not contain white space",
    }
}
export const website_url = {
    pattern: {
        value: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
        message: "Invalid URL"
    }
}
export const confirm_password_message = "Passwords Don't Match"
export const invalid_start_date = "Invalid start date"
export const invalid_date = "Invalid Date"

export const startDateValidate = {
    validate: {
        dateValidate: (value) => new Date(value) > new Date() || invalid_start_date,        
    },
}

export const textAndNumberAndSpecialCharacters = {pattern: {value: /^[a-zA-Z0-9 _,.;'"“”:.!\n-]*$/, message: "Invalid details"}}
export const textAndNumber = {pattern: {value: /^[a-zA-Z0-9 _.\-\n]*$/, message: "Invalid details"}}
export const onlyText = {pattern: {value: /^[a-zA-Z\n ]*$/, message: "Invalid text"}}
export const characterMaxLimit = (max) => `can not exceed more than ${max} characters`

export const isValidDate = {
    validate: {
        dateValidate: (value) => isDateValid(value) || invalid_date
    },
}

function isDateValid(date) {
    if (date) {
        return new Date(date) instanceof Date && !isNaN(new Date(date));
    } else {
        return true;
    }  
}